<div>
    <div class="beneficiary-container">
        <h4>Beneficiary Nomination</h4>
        <span>
            You are the primary member of this policy. In an unfortunate event that could lead to your death would you like to
            nominate a beneficiary who could claim the benefits of your policy. In case you do not nominate a beneficiary the
            payout will be to your Estate, leading to delays in getting the claim reimbursed to the rightful beneficiary.
        </span>
        <br />
        <div class="beneficiary-container-inner row">
            <div nz-col class="select-related-text col-lg-4 col-md-6 col-5">Do you want to add a beneficiary?</div>
            <mat-radio-group class="col-lg-4 col-md-4 col-7" aria-label="Do you want to add a beneficiary?">
                <mat-radio-button [checked]="isAddBeneficiary" (change)="onShouldAddBeneficiaryChange($event)" value="1"
                    ><span class="select-related-text">Yes</span></mat-radio-button
                >
                <mat-radio-button [checked]="isNotified" (change)="onShouldAddBeneficiaryChange($event)" value="2">
                    <span class="select-related-text">No</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <br />
        <div class="row" *ngIf="isAddBeneficiary">
            <div class="select-related-text col-lg-4 col-md-6 col-5">Do you want the beneficiary to be notified?</div>
            <mat-radio-group class="col-lg-4 col-md-4 col-7" aria-label="Do you want the beneficiary to be notified?">
                <mat-radio-button checked (change)="onShouldNotifyBeneficiaryChange($event)" value="3"
                    ><span class="select-related-text">Yes</span></mat-radio-button
                >
                <mat-radio-button (change)="onShouldNotifyBeneficiaryChange($event)" value="4"
                    ><span class="select-related-text">No</span></mat-radio-button
                >
            </mat-radio-group>
        </div>
    </div>

    <div class="container-fluid" *ngIf="isAddBeneficiary">
        <div class="row" *ngIf="!mobileView">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header card-header-rose card-header-icon">
                        <!-- <div class="card-icon">
                            <mat-icon>person_add</mat-icon>
                        </div> -->
                        <h4 class="card-title"></h4>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="text-center"></th>
                                        <th>Dependant Type</th>
                                        <th>Relationship</th>
                                        <th>First Name</th>
                                        <th>Surname</th>
                                        <th>ID Number</th>
                                        <th>Date Of Birth</th>
                                        <th>Passport Number</th>
                                        <th class="text-right">Phone Number</th>
                                        <th>Email</th>
                                        <th class="text-right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of beneficiaries?.relatedParties">
                                        <td class="text-center">
                                            <div>
                                                <div>
                                                    <mat-checkbox
                                                        (change)="onBeneficiarySelection($event, item)"
                                                        [checked]="isRelatedPartySelected(item)"
                                                    ></mat-checkbox>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="textTransform ">{{ item.dependentType == 'LOVEDONE' ? 'LOVED ONE' : item.dependentType }}</td>
                                        <td>{{ item.relation === 'ExtendedFamily' ? 'Other relatives' : item.relation }}</td>
                                        <td>{{ item.firstName }}</td>
                                        <td>{{ item.lastName }}</td>
                                        <td>{{ item.nationalIdNumber }}</td>
                                        <td>{{ item.dateOfBirth | date: 'dd MMM yyyy' }}</td>
                                        <td>{{ item.passportNumber }}</td>
                                        <td class="text-right">{{ item.phoneNumber == 0 ? '' : '+27' + item.phoneNumber }}</td>
                                        <td>{{ item.email }}</td>
                                        <td class="td-actions text-right">
                                            <i (click)="openOptModalBeneficiary(item)" class="material-icons edit-icon">edit</i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                                <button (click)="openOptModalBeneficiary()" class="btn-add">Add beneficiary</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid" *ngIf="isAddBeneficiary">
        <div class="row" *ngIf="mobileView">
            <div class="col-md-12">
                <div class="card">
                    <div class="accordion" style="margin-top: 30px" id="beneficiaryAccordion">
                        <div *ngFor="let item of beneficiaries?.relatedParties; let i = index" class="accordion-item">
                            <div class="accordion-header" id="beneficiary-heading-{{ i }}">
                                <div class="accordion-title">
                                    <div
                                        class="accordion-button collapsed"
                                        data-bs-toggle="collapse"
                                        [attr.data-bs-target]="'#beneficiary-collapse-' + i"
                                        aria-expanded="true"
                                        aria-controls="beneficiary-collapse-{{ i }}"
                                    ></div>
                                </div>

                                <div class="mobile-container">
                                    <mat-checkbox
                                        style="margin-right: 20px"
                                        (change)="onBeneficiarySelection($event, item)"
                                        [checked]="isRelatedPartySelected(item)"
                                    ></mat-checkbox>

                                    <div class="mobile-card-name-text mobile-card">
                                        <p class="name">{{ item.firstName }} {{ item.lastName }}</p>
                                        <p class="schedule-text-bold">
                                            <strong>Date of birth:</strong> {{ item.dateOfBirth | date: 'dd MMM yyyy' }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                id="beneficiary-collapse-{{ i }}"
                                class="collapse"
                                aria-labelledby="beneficiary-heading-{{ i }}"
                                data-bs-parent="#beneficiaryAccordion"
                            >
                                <div class="card-body">
                                    <div class="td-actions text-right">
                                        <i (click)="openOptModalBeneficiary(item)" class="material-icons edit-icon green-button"
                                            >edit</i
                                        >
                                    </div>
                                    <div>
                                        <p class="schedule-text-bold">
                                            <strong> Relationship: </strong
                                            >{{ item.relation === 'ExtendedFamily' ? 'Other relatives' : item.relation }}
                                        </p>
                                        <p class="schedule-text-bold">
                                            <strong> Dependant Type: </strong
                                            >{{ item.dependentType == 'LOVEDONE' ? 'LOVED ONE' : item.dependentType }}
                                        </p>
                                        <p class="schedule-text-bold"><strong>ID Number: </strong>{{ item.nationalIdNumber }}</p>
                                        <p class="schedule-text-bold">
                                            <strong>Date Of Birth:</strong> {{ item.dateOfBirth | date: 'dd MMM yyyy' }}
                                        </p>
                                        <p class="schedule-text-bold">
                                            <strong>Passport Number:</strong> {{ item.passportNumber }}
                                        </p>
                                        <p class="schedule-text-bold">
                                            <strong> Phone Number: </strong
                                            >{{ item.phoneNumber == 0 ? '' : '+27' + item.phoneNumber }}
                                        </p>
                                        <p class="schedule-text-bold"><strong>Email:</strong> {{ item.email }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="margin-left: -80px">
                        <button (click)="openOptModalBeneficiary()" class="btn-add">Add beneficiary</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
