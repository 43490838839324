import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IDriverDetails } from '../models/driver-details';
import { IResponseStatus } from '../models/response-status';
import { IUpdateMotoQuote } from '../models/update-motor.quote';
import { IVehicles } from '../models/vehicles-retrieval';

@Injectable({
    providedIn: 'root',
})
export class DriverService {
    public constructor(private readonly _http: HttpClient) {}

    public async getDrivers(): Promise<Array<IDriverDetails>> {
        try {
            const response = await this._http
                .post<Array<IDriverDetails>>(`${environment.serverApiUrl}policy/v1/assets/get-drivers`, null)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async updateDrivers(model: IDriverDetails): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<IResponseStatus>(`${environment.serverApiUrl}policy/v1/assets/update-drivers`, model)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async updateMotorQuote(model: IUpdateMotoQuote): Promise<IResponseStatus> {
        try {
            console.log('\n\n\n---------------------------------UpdateMotorQuote: REQ/MODEL ----------------------------------');
            console.log(JSON.stringify(model));
            console.log('----------------------------------------------------------------------------------------\n\n\n');

            const response = await this._http
                .post<IResponseStatus>(`${environment.serverApiUrl}policy/v1/assets/update-motor-quote`, model)
                .toPromise();

            console.log('\n\n\n---------------------------------UpdateMotorQuote: RESP----------------------------------');
            console.log(JSON.stringify(response));
            console.log('----------------------------------------------------------------------------------------\n\n\n');

            return response;
        } catch (error) {
            console.log('\n\n\n---------------------------------UpdateMotorQuote: ERROR----------------------------------');
            console.log(JSON.stringify(error));
            console.log('----------------------------------------------------------------------------------------\n\n\n');

            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getSavedMotorQuote(): Promise<IUpdateMotoQuote> {
        try {
            const response = await this._http
                .post<IUpdateMotoQuote>(`${environment.serverApiUrl}policy/v1/assets/get-motor-quote`, null)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getAllMotorQuotes(): Promise<IUpdateMotoQuote> {
        try {
            const response = await this._http
                .post<IUpdateMotoQuote>(`${environment.serverApiUrl}policy/v1/assets/get-all-motor-quote`, null)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getVehicles(): Promise<Array<IVehicles>> {
        try {
            const response = await this._http
                .post<Array<IVehicles>>(`${environment.serverApiUrl}policy/v1/assets/get-vehicles`, null)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getChannel(): Promise<any> {
        try {
            const response = await this._http.get<any>(`${environment.serverApiUrl}quote/v1/quote/quote-channel`).toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getMotorQuote(model: any): Promise<any> {
        try {
            console.log('\n\n\n---------------------------------GetMotorQuote: REQ/MODEL ----------------------------------');
            console.log(JSON.stringify(model));
            console.log('----------------------------------------------------------------------------------------\n\n\n');

            const response = await this._http
                .post<any>(`${environment.serverApiUrl}quote/v1/quote/get-motor-quote`, model)
                .toPromise();

            console.log('\n\n\n---------------------------------GetMotorquote: RESP----------------------------------');
            console.log(JSON.stringify(response));
            console.log('----------------------------------------------------------------------------------------\n\n\n');

            return response;
        } catch (error) {
            console.log('\n\n\n---------------------------------GetMotorquote: ERROR----------------------------------');
            console.log(JSON.stringify(error));
            console.log('----------------------------------------------------------------------------------------\n\n\n');

            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }



    public async submitMotorQuote(model: any): Promise<any> {
        try {
            console.log('\n\n\n---------------------------------GetMotorQuote: REQ/MODEL ----------------------------------');
            console.log(JSON.stringify(model));
            console.log('----------------------------------------------------------------------------------------\n\n\n');

            const response = await this._http
                .post<any>(`${environment.serverApiUrl}quote/v1/quote/submit-motor-quote`, model)
                .toPromise();

            console.log('\n\n\n---------------------------------GetMotorquote: RESP----------------------------------');
            console.log(JSON.stringify(response));
            console.log('----------------------------------------------------------------------------------------\n\n\n');

            return response;
        } catch (error) {
            console.log('\n\n\n---------------------------------GetMotorquote: ERROR----------------------------------');
            console.log(JSON.stringify(error));
            console.log('----------------------------------------------------------------------------------------\n\n\n');

            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }



    public async deleteMotorQuote(model: any): Promise<any> {
        try {
            const response = await this._http
                .post<any>(`${environment.serverApiUrl}policy/v1/assets/delete-motor-quote`, model)
                .toPromise();

            return response;
        } catch (error) {
            console.log(JSON.stringify(error));

            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    

    public async updateVehicles(model: IVehicles): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<IResponseStatus>(`${environment.serverApiUrl}policy/v1/assets/update-vehicles`, model)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }
}
