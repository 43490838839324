import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-timeout-popup',
  templateUrl: './timeout-popup.component.html',
  styleUrls: ['./timeout-popup.component.scss']
})
export class TimeoutPopupComponent implements OnInit {

  title: string;
  body: string;

  constructor(
    public dialogRef: MatDialogRef<TimeoutPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: any, body: any },
    private route: Router) {

    this.title = data.title;
    this.body = data.body;
  }

  close() {
    this.dialogRef.close(true);
  }

  signout() {
    localStorage.clear();
    localStorage.setItem('lastRoute', this.route.url);
    this.route.navigate(['/sign-in']);
    this.dialogRef.close(true);
  }

  ngOnInit(): void {
  }

}
