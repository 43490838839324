<!-- app.component.html -->

<div class="card-body" *ngIf="!mobileView">
    <div style="text-align: center">
        <div class="card-icon">
            <img style="width: 410px" src="{{ getInsurerLogo(quotes.insuranceProvider) }}" alt="" />
        </div>

        <strong style="font-weight: bold; font-weight: 200; font-size: 22px">{{ quotes?.planName }}</strong>

        <h1>{{ quotes?.finalQuote.premium | zarCurrency }} per month</h1>
    </div>

    <div *ngFor="let member of quotes.breakUpQuote; let i = index">
        <div>
            <table class="table">
                <tbody>
                    <tr>
                        <td >
                            {{ member.firstName }} {{ member.surname }} ({{ member.partyType | formatMainMember }})
                        </td>
                        <td class="schedule-text-bold">Identity/passport number</td>
                        <td>{{ member.identityNumber }}</td>
                        <td class="schedule-text-bold">Date of birth</td>
                        <td>{{ member.dateOfBirth | date : 'dd MMM yyyy' }}</td>
                    </tr>
                </tbody>
            </table>

            <table class="table" *ngIf="!isCoverAmountChildren(member.benefits)">
                <thead>
                    <tr>
                        <th style="width: 45%" class="schedule-text-bold">Benefit Name</th>
                        <th style="width: 15%" class="schedule-text-bold">Coverage</th>
                        <th style="width: 35%" class="schedule-text-bold">Reason</th>
                    </tr>
                </thead>
                <tbody>
                    <div>
                        <h4 class="requested">Benefits requested</h4>
                        <span *ngIf="requested(member.benefits) === 0">** None is covered **</span>
                    </div>

                    <tr *ngFor="let benefit of member.benefits">
                        <ng-container *ngIf="benefit.mandatory || benefit.covered">
                            <ng-container
                                *ngIf="
                                    benefit.magisId !== 'F000000012' &&
                                    benefit.magisId !== 'F000000006' &&
                                    benefit.mandatory === true &&
                                    solutionType === 'Prime'
                                "
                            >
                                <td>{{ benefit.name }}</td>
                                <ng-container
                                    *ngIf="
                                        benefit.covered == true &&
                                            benefit.value !== '0' 
                                        else notCovered
                                    "
                                >
                                    <td>
                                        {{ benefit.value | zarCurrency }}
                                    </td>
                                </ng-container>
                                <ng-template #notCovered>
                                    <th *ngIf="benefit.covered == false"><mat-icon style="color: red">close</mat-icon></th>
                                    <th *ngIf="benefit.covered === true">
                                        <mat-icon style="margin-top: -7px" class="custom-icon">done</mat-icon>
                                    </th>
                                </ng-template>

                                <td>{{ benefit?.reason }}</td>
                            </ng-container>
                        </ng-container>
                    </tr>

                    <ng-container *ngIf="solutionType === 'Custom'">
                        <tr *ngFor="let benefit of member.benefits">
                            <ng-container
                                *ngIf="
                                    benefit.mandatory ||
                                        benefit.covered ||
                                        !(benefit.magisId === 'F000000001' && benefit.covered === false);
                                    else iscoverd
                                "
                            >
                                <ng-container *ngIf="benefit.mandatory === true">
                                    <td>{{ benefit.name }}</td>
                                    <ng-container
                                        *ngIf="
                                            benefit.covered == true &&
                                                benefit.value !== '0' 
                                            else notCovered
                                        "
                                    >
                                        <td>
                                            {{ benefit.value | zarCurrency }}
                                        </td>
                                    </ng-container>
                                    <ng-template #notCovered>
                                        <th *ngIf="benefit.covered == false"><mat-icon style="color: red">close</mat-icon></th>
                                        <th *ngIf="benefit.covered === true">
                                            <mat-icon style="margin-top: -7px" class="custom-icon">done</mat-icon>
                                        </th>
                                    </ng-template>

                                    <td>{{ benefit?.reason }}</td>
                                </ng-container>
                            </ng-container>
                        </tr>
                    </ng-container>

                    <div>
                        <h4 class="requested">Benefits not requested</h4>
                        <span *ngIf="notRequested(member.benefits) === 0">** None is covered **</span>
                    </div>

                    <tr *ngFor="let benefit of member.benefits">
                        <ng-container *ngIf="benefit.mandatory || benefit.covered">
                            <ng-container
                                *ngIf="
                                    benefit.magisId !== 'F000000012' &&
                                    benefit.magisId !== 'F000000006' &&
                                    benefit.mandatory === false &&
                                    benefit.covered === true &&
                                    solutionType === 'Prime'
                                "
                            >
                                <td>{{ benefit.name }}</td>
                                <ng-container
                                    *ngIf="
                                        benefit.covered == false &&
                                            benefit.value !== '0' 
                                         
                                        else notCovered
                                    "
                                >
                                    <td>
                                        {{ benefit.value | zarCurrency }}
                                    </td>
                                </ng-container>
                                <ng-template #notCovered>
                                    <th *ngIf="benefit.covered == false"><mat-icon style="color: red">close</mat-icon></th>
                                    <th *ngIf="benefit.covered === true">
                                        <mat-icon style="margin-top: -7px" class="custom-icon">done</mat-icon>
                                    </th>
                                </ng-template>

                                <td>{{ benefit?.reason }}</td>
                            </ng-container>
                        </ng-container>
                    </tr>

                    <ng-container *ngIf="solutionType === 'Custom'">
                        <tr *ngFor="let benefit of member.benefits">
                            <ng-container *ngIf="benefit.mandatory || benefit.covered">
                                <ng-container *ngIf="benefit.mandatory === false">
                                    <td>{{ benefit.name }}</td>
                                    <ng-container
                                        *ngIf="
                                            benefit.covered == true &&
                                                benefit.value !== '0' 
                                            else notCovered
                                        "
                                    >
                                        <td>
                                            {{ benefit.value | zarCurrency }}
                                        </td>
                                    </ng-container>
                                    <ng-template #notCovered>
                                        <th *ngIf="benefit.covered == false"><mat-icon style="color: red">close</mat-icon></th>
                                        <th *ngIf="benefit.covered === true">
                                            <mat-icon style="margin-top: -7px" class="custom-icon">done</mat-icon>
                                        </th>
                                    </ng-template>

                                    <td>{{ benefit?.reason }}</td>
                                </ng-container>
                            </ng-container>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <td style="color: #8d55ca; font-weight: 800" >
                Premium : {{ member.premium | zarCurrency }}
                <span *ngIf="member.reason && member.reason !== 'null' && member.reason !== ''">
                    | Reason : {{ member.reason }}</span
                >
            </td>

            <br />
        </div>
    </div>

    <button class="btn btn-fill btn-round float-right" style="margin-top: -15px" (click)="onDoneClick()">Done</button>
</div>

<div *ngIf="mobileView">
    <div class="card-body">
        <div style="text-align: center">
            <div class="card-icon">
                <img style="width: 260px" src="{{ getInsurerLogo(quotes.insuranceProvider) }}" alt="" />
            </div>

            <!-- <div style="color: #aa80d7; text-align: center; font-weight: 200; font-size: 22px">
                <span>{{ quotes.insuranceProvider }}</span>
            </div> -->
            <strong style="font-weight: bold; font-weight: 200; font-size: 22px">{{ quotes?.planName }}</strong>

            <h1>{{ quotes?.finalQuote.premium | zarCurrency }} per month</h1>

            <!-- <strong style="font-weight: bold">{{ quotes?.planName }}</strong> -->
        </div>

        <div style="text-align: center">
            <div class="accordion" id="memberAccordion">
                <div *ngFor="let member of quotes.breakUpQuote; let i = index" class="accordion-item">
                    <div class="accordion-header" id="member-heading-{{ i }}">
                        <div class="accordion-title">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                [attr.data-bs-target]="'#member-collapse-' + i"
                                aria-expanded="true"
                                aria-controls="member-collapse-{{ i }}"
                            >
                                <div class="row">
                                    <div style="display: flex">
                                        <p class="name">{{ member.firstName }} {{ member.surname }}</p>
                                        <p>({{ member.partyType }})</p>
                                    </div>
                                    <p class="schedule-text-bold">
                                        {{ member.identityNumber }} / {{ member.dateOfBirth | date : 'dd MMM yyyy' }}
                                    </p>
                                </div>
                            </button>
                        </div>
                    </div>

                    <div
                        id="member-collapse-{{ i }}"
                        class="collapse"
                        aria-labelledby="member-heading-{{ i }}"
                        [attr.data-bs-parent]="'#memberAccordion'"
                    >
                        <div class="card-body">
                            <!-- Identity Accordion -->
                            <div class="accordion" [attr.id]="'identityAccordion-' + i">
                                <div class="accordion-item"></div>
                            </div>

                            <!-- Benefits Accordions -->
                            <div
                                *ngIf="!isCoverAmountChildren(member.benefits)"
                                class="accordion"
                                [attr.id]="'benefitAccordion-' + i"
                            >
                                <div class="accordion-item">
                                    <ng-container *ngIf="solutionType === 'Prime'">
                                        <div class="accordion-header" id="benefit-heading-{{ i }}-10">
                                            <div class="accordion-title">
                                                <button
                                                    class="accordion-button collapsed"
                                                    type="button"
                                                    style="
                                                        color: #fff;
                                                        font-weight: 500;
                                                        background: #8d55ca;
                                                        margin-bottom: 20px;
                                                    "
                                                    data-bs-toggle="collapse"
                                                    [attr.data-bs-target]="'#benefit-collapse-' + i + '-' + 10"
                                                    aria-expanded="false"
                                                    aria-controls="benefit-collapse-{{ i }}-10"
                                                >
                                                    Benefits not requested
                                                </button>
                                            </div>
                                        </div>

                                        <div
                                            id="benefit-collapse-{{ i }}-10"
                                            class="collapse"
                                            aria-labelledby="benefit-heading-{{ i }}-10"
                                            [attr.data-bs-parent]="'#benefitAccordion-' + i"
                                        >
                                            <div *ngFor="let benefit of notRequestedMobile(member.benefits); let j = index">
                                                <ng-container *ngIf="benefit.mandatory || benefit.covered">
                                                    <ng-container
                                                        *ngIf="
                                                            benefit.magisId !== 'F000000012' &&
                                                            benefit.magisId !== 'F000000006' &&
                                                            benefit.mandatory === false &&
                                                            benefit.covered === true &&
                                                            solutionType === 'Prime'
                                                        "
                                                    >
                                                        <div class="benefit-card">
                                                            <div class="mobile-card-more">
                                                                <p class="schedule-text-bold benefits-name">
                                                                    {{ benefit.name }}
                                                                </p>
                                                                <ng-container
                                                                    *ngIf="
                                                                        benefit.covered == false &&
                                                                            benefit.value !== '0' 
                                                                        else notCovered
                                                                    "
                                                                >
                                                                    <p>
                                                                        {{
                                                                            benefit.value
                                                                                | currency : 'R ' : 'symbol' : '1.2-2'
                                                                                | replace : ',' : ' '
                                                                        }}
                                                                    </p>
                                                                </ng-container>
                                                                <ng-template #notCovered>
                                                                    <p *ngIf="benefit.covered == false">
                                                                        <mat-icon style="color: red">close</mat-icon>
                                                                    </p>

                                                                    <p
                                                                        class="schedule-text-bold"
                                                                        *ngIf="benefit.covered === true"
                                                                    >
                                                                        <mat-icon style="margin-top: -7px" class="custom-icon"
                                                                            >done</mat-icon
                                                                        >
                                                                    </p>
                                                                    <hr />
                                                                </ng-template>
                                                            </div>

                                                            <p *ngIf="benefit?.reason" class="schedule-text-bold">
                                                                {{ benefit?.reason }}
                                                            </p>
                                                            <hr />
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="solutionType === 'Custom'">
                                        <div class="accordion-header" id="benefit-heading-{{ i }}-11">
                                            <div class="accordion-title">
                                                <button
                                                    class="accordion-button collapsed"
                                                    type="button"
                                                    style="
                                                        color: #fff;
                                                        font-weight: 500;
                                                        background: #8d55ca;
                                                        margin-bottom: 20px;
                                                    "
                                                    data-bs-toggle="collapse"
                                                    [attr.data-bs-target]="'#benefit-collapse-' + i + '-' + 11"
                                                    aria-expanded="false"
                                                    aria-controls="benefit-collapse-{{ i }}-11"
                                                >
                                                    beneftis not requested
                                                </button>
                                            </div>
                                        </div>

                                        <div
                                            id="benefit-collapse-{{ i }}-11"
                                            class="collapse"
                                            aria-labelledby="benefit-heading-{{ i }}-11"
                                            [attr.data-bs-parent]="'#benefitAccordion-' + i"
                                        >
                                            <div *ngFor="let benefit of notRequestedMobile(member.benefits); let j = index">
                                                <ng-container *ngIf="benefit.mandatory || benefit.covered">
                                                    <div class="benefit-card">
                                                        <div class="mobile-card-more">
                                                            <p class="schedule-text-bold benefits-name">
                                                                {{ benefit.name }}
                                                            </p>
                                                            <ng-container
                                                                *ngIf="
                                                                    (benefit.covered == true && benefit.mandatory === true) ||
                                                                        benefit.covered === false ||
                                                                        benefit.magisId === 'F000000001' ||
                                                                        benefit.magisId === 'F000000006' ||
                                                                        benefit.magisId === 'F000000012';
                                                                    else notCovered
                                                                "
                                                            >
                                                                <p class="schedule-text-bold">
                                                                    {{
                                                                        benefit.value
                                                                            | currency : 'R ' : 'symbol' : '1.2-2'
                                                                            | replace : ',' : ' '
                                                                    }}
                                                                </p>
                                                            </ng-container>
                                                            <ng-template #notCovered>
                                                                <p class="schedule-text-bold" *ngIf="benefit.covered == false">
                                                                    <mat-icon style="color: red">close</mat-icon>
                                                                </p>

                                                                <p class="schedule-text-bold" *ngIf="benefit.covered === true">
                                                                    <mat-icon style="margin-top: -7px" class="custom-icon"
                                                                        >done</mat-icon
                                                                    >
                                                                </p>
                                                            </ng-template>
                                                        </div>

                                                        <p *ngIf="benefit?.reason" class="schedule-text-bold">
                                                            {{ benefit?.reason }}
                                                        </p>
                                                        <hr />
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>

                            <div
                                *ngIf="!isCoverAmountChildren(member.benefits)"
                                class="accordion"
                                [attr.id]="'benefitAccordion-' + i"
                            >
                                <div class="accordion-item">
                                    <ng-container *ngIf="solutionType === 'Prime'">
                                        <div class="accordion-header" id="benefit-heading-{{ i }}-12">
                                            <div class="accordion-title">
                                                <button
                                                    class="accordion-button collapsed"
                                                    type="button"
                                                    style="
                                                        color: #fff;
                                                        font-weight: 500;
                                                        background: #8d55ca;
                                                        margin-bottom: 20px;
                                                    "
                                                    data-bs-toggle="collapse"
                                                    [attr.data-bs-target]="'#benefit-collapse-' + i + '-' + 12"
                                                    aria-expanded="false"
                                                    aria-controls="benefit-collapse-{{ i }}-12"
                                                >
                                                    Benefits requested
                                                </button>
                                            </div>
                                        </div>

                                        <div
                                            id="benefit-collapse-{{ i }}-12"
                                            class="collapse"
                                            aria-labelledby="benefit-heading-{{ i }}-12"
                                            [attr.data-bs-parent]="'#benefitAccordion-' + i"
                                        >
                                            <div *ngFor="let benefit of requestedMobile(member.benefits); let j = index">
                                                <ng-container *ngIf="benefit.mandatory || benefit.covered">
                                                    <ng-container
                                                        *ngIf="
                                                            (benefit.name !== 'Memorial benefit' &&
                                                                benefit.name !== 'Grocery benefit' &&
                                                                solutionType === 'Prime' &&
                                                                benefit.mandatory === true) ||
                                                            benefit.covered === true
                                                        "
                                                    >
                                                        <div class="benefit-card">
                                                            <div class="mobile-card-more">
                                                                <p class="schedule-text-bold benefits-name">
                                                                    {{ benefit.name }}
                                                                </p>
                                                                <ng-container
                                                                    *ngIf="
                                                                        benefit.covered == true &&
                                                                            benefit.value !== '0' 
                                                                        else notCovered
                                                                    "
                                                                >
                                                                    <p class="schedule-text-bold">
                                                                        {{
                                                                            benefit.value
                                                                                | currency : 'R ' : 'symbol' : '1.2-2'
                                                                                | replace : ',' : ' '
                                                                        }}
                                                                    </p>
                                                                </ng-container>
                                                                <ng-template #notCovered>
                                                                    <p
                                                                        class="schedule-text-bold"
                                                                        *ngIf="benefit.covered == false"
                                                                    >
                                                                        <mat-icon style="color: red">close</mat-icon>
                                                                    </p>

                                                                    <p
                                                                        class="schedule-text-bold"
                                                                        *ngIf="benefit.covered === true"
                                                                    >
                                                                        <mat-icon style="margin-top: -7px" class="custom-icon"
                                                                            >done</mat-icon
                                                                        >
                                                                    </p>
                                                                </ng-template>
                                                            </div>

                                                            <p *ngIf="benefit?.reason" class="schedule-text-bold">
                                                                {{ benefit?.reason }}
                                                            </p>
                                                            <hr />
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="solutionType === 'Custom'">
                                        <div class="accordion-header" id="benefit-heading-{{ i }}-13">
                                            <div class="accordion-title">
                                                <button
                                                    class="accordion-button collapsed"
                                                    type="button"
                                                    style="
                                                        color: #fff;
                                                        font-weight: 500;
                                                        background: #8d55ca;
                                                        margin-bottom: 20px;
                                                    "
                                                    data-bs-toggle="collapse"
                                                    [attr.data-bs-target]="'#benefit-collapse-' + i + '-' + 13"
                                                    aria-expanded="false"
                                                    aria-controls="benefit-collapse-{{ i }}-11"
                                                >
                                                    beneftis requested
                                                </button>
                                            </div>
                                        </div>

                                        <div
                                            id="benefit-collapse-{{ i }}-13"
                                            class="collapse"
                                            aria-labelledby="benefit-heading-{{ i }}-13"
                                            [attr.data-bs-parent]="'#benefitAccordion-' + i"
                                        >
                                            <div *ngFor="let benefit of requestedMobile(member.benefits); let j = index">
                                                <ng-container *ngIf="benefit.mandatory || benefit.covered">
                                                    <div class="benefit-card">
                                                        <div class="mobile-card-more">
                                                            <p class="schedule-text-bold benefits-name">
                                                                {{ benefit.name }}
                                                            </p>
                                                            <ng-container
                                                                *ngIf="
                                                                    (benefit.covered == true && benefit.mandatory === true) ||
                                                                        benefit.covered === true ||
                                                                        benefit.magisId === 'F000000001' ||
                                                                        benefit.magisId === 'F000000006' ||
                                                                        benefit.magisId === 'F000000012';
                                                                    else notCovered
                                                                "
                                                            >
                                                                <p class="schedule-text-bold">
                                                                    {{
                                                                        benefit.value
                                                                            | currency : 'R ' : 'symbol' : '1.2-2'
                                                                            | replace : ',' : ' '
                                                                    }}
                                                                </p>
                                                            </ng-container>
                                                            <ng-template #notCovered>
                                                                <p class="schedule-text-bold" *ngIf="benefit.covered == false">
                                                                    <mat-icon style="color: red">close</mat-icon>
                                                                </p>

                                                                <p class="schedule-text-bold" *ngIf="benefit.covered === true">
                                                                    <mat-icon style="margin-top: -7px" class="custom-icon"
                                                                        >done</mat-icon
                                                                    >
                                                                </p>
                                                            </ng-template>
                                                        </div>

                                                        <p *ngIf="benefit?.reason" class="schedule-text-bold">
                                                            {{ benefit?.reason }}
                                                        </p>
                                                        <hr />
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>

                            <div style="color: #8d55ca; font-weight: 800">
                                <strong>Premium:</strong>
                                {{ member.premium | zarCurrency }}
                                <span *ngIf="member.reason && member.reason !== 'null' && member.reason !== ''">
                                    | <strong> Reason: </strong>{{ member.reason }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <button class="btn btn-fill btn-round float-right" (click)="onDoneClick()">Done</button>
</div>
