import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountTypeEnum } from 'src/app/core/enums/account-type.enum';
import { ConvertEnum } from 'src/app/core/enums/convert.enum';
import { DependantTypeEnum } from 'src/app/core/enums/related-party-type.enum';
import { IDropdown } from 'src/app/core/models/drop-down';
import { HttpStatusCode } from 'src/app/core/models/http-status-code.model';
import { IReferenceData } from 'src/app/core/models/reference-data';
import { IRelatedParty } from 'src/app/core/models/related-party';
import { BeneficiaryService } from 'src/app/core/services/beneficiary.service';
import { LovDataService } from 'src/app/core/services/lov-data.service';
import { RelatedPartyService } from 'src/app/core/services/related-party.service';

import { validIdnumber } from 'src/app/core/validators/idnumber.validator';
import { IdNumberValidator } from 'src/app/core/_helpers/idnumber.validator';
import { MessageComponent } from '../message/message.component';
import { Notifications } from '../notifications.component';
import { MobileNumberValidator } from 'src/app/core/validators/mobile-number-validator';

@Component({
    selector: 'app-form-beneficiary',
    templateUrl: './form-beneficiary.component.html',
    styleUrls: ['./form-beneficiary.component.scss'],
})
export class FormBeneficiaryComponent implements OnInit {
    form: FormGroup;
    public loading: boolean = false;
    public titles: Array<IReferenceData> = [];
    public saving: boolean = false;
    public genders: Array<IReferenceData> = [];
    public banks: Array<IReferenceData> = [];
    public relations: Array<IReferenceData> = [];
    public accounttypes: Array<IDropdown>;
    public beneficiaryData: any;
    public formIsValid: boolean;


    public controlState : any = "Add";

    public today = new Date();
    public minDate = new Date(1900, 0, 1);
    public date = new Date(this.today.getFullYear() - 16, 0, 1);

    idNumberPattern = this._idNumberValidator.IdNumberRegex;

    isValidSAID = false;
    isIdNumberTouched = false;

    public constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            noText: string;
            confirmText: string;
            message: string;
            title: string;
        },
        public dialogRef: MatDialogRef<MessageComponent>,
        private readonly formBuilder: FormBuilder,
        private readonly _beneficiaryService: BeneficiaryService,
        private readonly _notification: Notifications,
        private readonly _lovDataService: LovDataService,
        private readonly _idNumberValidator: IdNumberValidator,
        private readonly _relatedPartyService: RelatedPartyService
    ) {}

    public async getReferenceData(type: string): Promise<Array<IReferenceData>> {
        return this._lovDataService.getReferenceData(type);
    }

    public async ngOnInit(): Promise<void> {
        this.titles = await this.getReferenceData('titles');
        this.genders = await this.getReferenceData('genders');
        this.banks = await this.getReferenceData('banks');
        this.relations = await this.getReferenceData('relations');
        this.accounttypes = ConvertEnum.convertEnumArray(AccountTypeEnum);

        this.beneficiaryData = JSON.parse(localStorage.getItem('beneficiaryData'));

        this.controlState = this.beneficiaryData ? "Update" : "Add";  // we should have an ENUM for this.

        this.formInit();

        if (this.beneficiaryData !== undefined && this.beneficiaryData !== null ) {
            this.form.patchValue({
                dependantId: this.beneficiaryData.dependantId,
                title: this.beneficiaryData.title,
                firstName: this.beneficiaryData.firstName,
                lastName: this.beneficiaryData.lastName,
                phoneNumber: this.beneficiaryData.phoneNumber,
                dateOfBirth: new Date(this.beneficiaryData.dateOfBirth),
                email: this.beneficiaryData.email,
                gender: this.beneficiaryData.gender,
                nationalIdNumber: this.beneficiaryData.nationalIdNumber,
                idType: this.beneficiaryData.idType,
                passportNumber: this.beneficiaryData.passportNumber,
                bankName: this.beneficiaryData.bankDetails?.bankName,
                accountHolderName: this.beneficiaryData.bankDetails?.accountHolderName,
                accountNumber: this.beneficiaryData.bankDetails?.accountNumber,
                accountType: this.beneficiaryData.bankDetails?.accountType,
                branchName: this.beneficiaryData.bankDetails?.branchName,
                bankCode: this.beneficiaryData.bankDetails?.bankCode,
                branchCode: this.beneficiaryData.bankDetails?.branchCode,
                relation: this.beneficiaryData.relation,
            });
        }
    }

    isFieldValid(form: FormGroup, field: string) {

        var isNotValid = !form.get(field).valid && form.get(field).touched;

        let val = form.get(field).value;
        if (field === 'phoneNumber' && val && form.get(field).touched) {
            isNotValid = !MobileNumberValidator.isValidSAMobileNumber(val);
        }

        return isNotValid;
    }

    onBlur(field: any) {
        if (field === 'nationalIdNumber') this.isIdNumberTouched = true;
    }

    public onchangeBankName(event: any): void {
        let bank = this.banks.find(x => { return x.name === event.value });
        this.form.patchValue({
            branchCode: bank.value,
            branchName: 'Universal branch'
        });
    }

    onIdNumberCapture() {
        let value = this.form.value.nationalIdNumber;
        let result = this._idNumberValidator.validate(value);
        this.isValidSAID = result.valid;
        this.updateGenderControl(result.gender);
        this.updateDoBControl(result.dateOfBirth);
    }

    private updateGenderControl(value: any) {
        this.form.patchValue({
            gender: value,
        });
        this.form.updateValueAndValidity({ onlySelf: true, emitEvent: true });
    }

    private updateDoBControl(value: any) {
        this.date = value;
        this.form.patchValue({
            dateOfBirth: value,
        });
        this.form.updateValueAndValidity({ onlySelf: true, emitEvent: true });
    }

    public async done(): Promise<void> {
        this.loading = true;
        let response;
        try {
            this.validateForm();
            this.saving = true;

            if (this.form.valid || this.formIsValid) {
                const model: IRelatedParty = {
                    dependantId: this.form.value.dependantId,
                    title: this.form.value.title,
                    firstName: this.form.value.firstName,
                    lastName: this.form.value.lastName,
                    phoneNumber: this.form.value.phoneNumber,
                    dateOfBirth: this.form.value.dateOfBirth,
                    email: this.form.value.email,
                    gender: this.form.value.gender,
                    nationalIdNumber: this.form.value.nationalIdNumber,
                    passportNumber: this.form.value.passportNumber,
                    income: 0,
                    initials: this.form.value.initials,
                    maritalStatus: this.form.value.maritalStatus,
                    relation: this.form.value.relation,
                    dependentType: DependantTypeEnum.BENEFICIARY,
                    bankDetails: {
                        bankName: this.form.value.bankName,
                        accountHolderName: this.form.value.accountHolderName,
                        accountNumber: this.form.value.accountNumber,
                        accountType: this.form.value.accountType,
                        branchName: this.form.value.branchName,
                        bankCode: this.form.value.bankCode,
                        branchCode: this.form.value.branchCode,
                        ifscCode: this.form.value.ifscCode,
                        debitOrderDay: 0,
                    },
                };

                // if (this.beneficiaryData !== null || this.beneficiaryData !== undefined) {
                if (model.dependantId !== null && model.dependantId !== undefined){
                    response = await this._relatedPartyService.updateUserRelatedParty(model);
                } else {
                    response = await this._relatedPartyService.addUserRelatedParty(model);
                }

                if (response.respCode === HttpStatusCode.ACCEPTED || response.respCode === HttpStatusCode.OK) {
                    this._notification.showNotification(response.respMessage, 'success');
                    this.loading = false;
                } else {
                    this._notification.showNotification(response.respMessage, 'danger');
                    this.loading = false;
                    return;
                }
                this.loading = false;
            } else {
                this.loading = false;
            }
        } catch (error) {
            this.loading = false;
        }
    }

    private validateForm() {
        const mandatoryFields = [
            'firstName',
            'lastName',
            'phoneNumber',
            'dateOfBirth',
            'gender',
        ];
        this.formIsValid = true;
        mandatoryFields?.forEach(field => {
            const value = this.form.get(field).value;
            this.formIsValid = this.formIsValid && value !== null && value !== undefined;
        });
    }

    isValidGender(form: FormGroup, field: string) {
        var returnVal;
        // const gender = form.get('gender').value;
        // const fieldValue = form.get(field).value;

        // if (field === 'title') {
        //     const tGender = GenderValidator.getGenderOfTitle(fieldValue);
        //     returnVal = (gender !== tGender && gender !== null && tGender !== null && gender !== GenderEnum.Others);
        // }

        return returnVal;
    }

    public formInit() {
        this.form = this.formBuilder.group({
            dependantId: [null],
            title: [null],
            firstName: [null, Validators.required],
            lastName: [null, Validators.required],
            phoneNumber: [null, Validators.required],
            dateOfBirth: [null, Validators.required],
            email: [null],
            gender: [null, Validators.required],
            nationalIdNumber: [null],
            passportNumber: [null],
            idType: 'nationalid', 
            bankName: [null],
            accountHolderName: [null],
            accountNumber: [null],
            accountType: [null],
            branchName: [null],
            bankCode: [null],
            branchCode: [null],
            relation: [null, Validators.required],
        });
    }
}
