import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserTaskTypeEnum } from '../enums/user-task-type.enum';
import { UserActivityMapper } from '../mappers/user-activity-mapper';
import { UserDashboardMapper } from '../mappers/user-dashboard-mapper';
import { UserTaskMapper } from '../mappers/user-task-mapper';
import { AddressStatusEnum, IAddressDetails, IAddressDetailsData } from '../models/address';
import { IContacts, IContactsResponse } from '../models/contact';
import { IUserActivity, IUserDashboard, IUserTask, TaskStatusEnum } from '../models/dashboard';
import { ILicenseData, ILicenseDetails } from '../models/license';
import { IResponseStatus } from '../models/response-status';
import { IUpdateUserDetails } from '../models/update-user-details';
import { IUpdateUserbanks } from '../models/update-userbanks';
import { IUserBanks } from '../models/user-banks';
import { IProfileDetails, IUserDetails } from '../models/user-Details';
import { IUerRelatedPartiesRetrieval } from '../models/user-related-parties-retrieval';
import { Store } from '@ngrx/store';
import * as userActions from '../../../app/ngrx/actions/user-detail.action';
import { clearPersonalDetail } from 'src/app/ngrx/actions/user-detail.action';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    public constructor(private readonly _http: HttpClient, private _store: Store) {}

    // API_HEADER: any = { 'X-Channel': 'WEB' }

    public async getUserDetails(): Promise<IUserDetails> {
        let data: IUserDetails;
        this._store
            .select((state: any) => state?.user)
            .subscribe(data => {
                data = data?.userDetails;
            });

        if (!data) {
            try {
                var response = await this._http
                    .post<IUserDetails>(`${environment.serverApiUrl}auth/v1/kyc/get-user-details`, null)
                    .toPromise();
                this._store.dispatch(userActions.setUserDetails({ userDetails: response }));

                return response;
            } catch (error) {
                if (error && error instanceof ProgressEvent) {
                    throw new Error('A connection could not be established. Please contact an administrator.');
                }
                throw Error();
            }
        } else {
            return data;
        }
    }

    public async updateUserDetails(model: any): Promise<any> {
        try {
            console.log('\n\n\n---------------------------------UpdateUserDetails: REQ/MODEL ----------------------------------');
            console.log(JSON.stringify(model));
            console.log('----------------------------------------------------------------------------------------\n\n\n');

            const response = await this._http
                .post<void>(
                    `${environment.serverApiUrl}auth/v1/kyc/update-user-details`,
                    model
                    // , { headers: this.API_HEADER }
                )
                .toPromise();

            localStorage.removeItem('userDetail');
            this._store.dispatch(clearPersonalDetail());

            console.log(
                '\n\n\n---------------------------------UpdateUserDetails: RESP/MODEL ----------------------------------'
            );
            console.log(JSON.stringify(response));
            console.log('----------------------------------------------------------------------------------------\n\n\n');

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    // public async updateUserProfile(model: IProfileDetails): Promise<IResponseStatus> {
    //     try {
    //         const response = await this._http
    //             .post<IResponseStatus>(
    //                 `${environment.serverApiUrl}auth/v1/kyc/update-user-details`,
    //                 model
    //                 // , { headers: this.API_HEADER }
    //             )
    //             .toPromise();

    //         localStorage.removeItem('userDetail');
    //         this._store.dispatch(clearPersonalDetail())
    //         return response;
    //     } catch (error) {
    //         if (error && error instanceof ProgressEvent) {
    //             throw new Error('A connection could not be established. Please contact an administrator.');
    //         }
    //         throw Error();
    //     }
    // }

    public async getUserBanks(): Promise<Array<IUserBanks>> {
        try {
            const response = await this._http
                .post<Array<IUserBanks>>(
                    `${environment.serverApiUrl}policy/v1/assets/get-userbanks`,
                    null
                    // , { headers: this.API_HEADER }
                )
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async updateUserBanks(model: IUpdateUserbanks): Promise<any> {
        try {
            const response = await this._http
                .post<any>(
                    `${environment.serverApiUrl}policy/v1/assets/update-userbank`,
                    model
                    // , { headers: this.API_HEADER }
                )
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async updateUserBanksRquest(model: IUpdateUserbanks): Promise<any> {
        try {
            const response = await this._http
                .post<any>(
                    `${environment.serverApiUrl}policy/v1/assets/update-user-bank-request`,
                    model
                    // , { headers: this.API_HEADER }
                )
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async addUserBanks(model: IUpdateUserbanks): Promise<any> {
        try {
            const response = await this._http
                .post<any>(
                    `${environment.serverApiUrl}policy/v1/assets/add-userbank`,
                    model
                    // , { headers: this.API_HEADER }
                )
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async updateUserOTPMethod(sendOtp: string): Promise<any> {
        try {
            const response = await this._http
                .post<any>(
                    `${environment.serverApiUrl}auth/v1/auth/update-user-otpmethod`,
                    { otpMethod: sendOtp }
                    // , { headers: this.API_HEADER }
                )
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getUserRelatedParties(): Promise<IUerRelatedPartiesRetrieval> {
        try {
            const response = await this._http
                .post<IUerRelatedPartiesRetrieval>(
                    `${environment.serverApiUrl}policy/v1/assets/get-user-related-parties`,
                    null
                    // , { headers: this.API_HEADER }
                )
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getUserLicenseDetails(): Promise<ILicenseDetails> {
        try {
            const response = await this._http
                .post<ILicenseDetails>(
                    `${environment.serverApiUrl}auth/v1/auth/get-user-license-details`,
                    null
                    // , { headers: this.API_HEADER }
                )
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async addUserLicenseDetails(model: ILicenseData): Promise<IResponseStatus> {
        try {
            console.log('\n\n\n---------------------------------AddLicenseDetails: REQ/MODEL ----------------------------------');
            console.log(JSON.stringify(model));
            console.log('----------------------------------------------------------------------------------------\n\n\n');

            const response = await this._http
                .post<IResponseStatus>(
                    `${environment.serverApiUrl}auth/v1/auth/add-user-license-details`,
                    model
                    // , { headers: this.API_HEADER }
                )
                .toPromise();

            console.log(
                '\n\n\n---------------------------------AddLicenseDetails: RESP/MODEL ----------------------------------'
            );
            console.log(JSON.stringify(response));
            console.log('----------------------------------------------------------------------------------------\n\n\n');

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async updateUserLicenseDetails(model: ILicenseData): Promise<IResponseStatus> {
        try {
            console.log(
                '\n\n\n---------------------------------UpdateLicenseDetails: REQ/MODEL ----------------------------------'
            );
            console.log(JSON.stringify(model));
            console.log('----------------------------------------------------------------------------------------\n\n\n');

            const response = await this._http
                .post<IResponseStatus>(
                    `${environment.serverApiUrl}auth/v1/auth/update-user-license-details`,
                    model
                    // , { headers: this.API_HEADER }
                )
                .toPromise();

            console.log(
                '\n\n\n---------------------------------UpdateLicenseDetails: RESP/MODEL ----------------------------------'
            );
            console.log(JSON.stringify(response));
            console.log('----------------------------------------------------------------------------------------\n\n\n');

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getUserContacts(): Promise<IContactsResponse> {
        try {
            const response = await this._http
                .post<IContactsResponse>(
                    `${environment.serverApiUrl}auth/v1/kyc/get-user-contacts`,
                    null
                    // , { headers: this.API_HEADER }
                )
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async updateUserContact(model: IContacts): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<IResponseStatus>(
                    `${environment.serverApiUrl}auth/v1/kyc/update-user-contact`,
                    model
                    // , { headers: this.API_HEADER }
                )
                .toPromise();
            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async addUserContact(model: IContacts): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<IResponseStatus>(
                    `${environment.serverApiUrl}auth/v1/kyc/add-user-contact`,
                    model
                    // , { headers: this.API_HEADER }
                )
                .toPromise();
            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async addUserAddress(model: IAddressDetails): Promise<IResponseStatus> {
        try {
            model.status = AddressStatusEnum.ACTIVE;

            console.log('\n\n\n---------------------------------AddAddressDetails: REQ/MODEL ----------------------------------');
            console.log(JSON.stringify(model));
            console.log('----------------------------------------------------------------------------------------\n\n\n');

            const response = await this._http
                .post<IResponseStatus>(
                    `${environment.serverApiUrl}auth/v1/kyc/add-user-address`,
                    model
                    // , { headers: this.API_HEADER }
                )
                .toPromise();

            console.log(
                '\n\n\n---------------------------------AddAddressDetails: RESP/MODEL ----------------------------------'
            );
            console.log(JSON.stringify(response));
            console.log('----------------------------------------------------------------------------------------\n\n\n');

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getUserAddress(): Promise<IAddressDetailsData> {
        try {
            const response = await this._http
                .post<IAddressDetailsData>(
                    `${environment.serverApiUrl}auth/v1/kyc/get-user-address`,
                    null
                    // , { headers: this.API_HEADER }
                )
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async updateUserAddressRequest(model: IAddressDetails): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<IResponseStatus>(
                    `${environment.serverApiUrl}auth/v1/kyc/update-user-address-request`,
                    model
                    // , { headers: this.API_HEADER }
                )
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async updateUserAddress(model: IAddressDetails): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<IResponseStatus>(
                    `${environment.serverApiUrl}auth/v1/kyc/update-user-address`,
                    model
                    // , { headers: this.API_HEADER }
                )
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getAllDashboardItems(): Promise<IUserDashboard> {
        try {
            const response = await this._http
                .post<IUserDashboard>(
                    `${environment.serverApiUrl}auth/v1/dashboard/get-dashboard-stats`,
                    null
                    // , { headers: this.API_HEADER }
                )
                .toPromise();

            return UserDashboardMapper.GetUserDashboard(response);
            // return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getUserActivities(request: any): Promise<Array<IUserActivity>> {
        try {
            const response = await this._http
                .post<any>(
                    `${environment.serverApiUrl}auth/v1/insight/get-user-activities`,
                    request
                    // , { headers: this.API_HEADER }
                )
                .toPromise();

            return UserActivityMapper.GetUserActivities(response);
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getUserActiveTasks(request: any): Promise<any> {
        let response: IResponseStatus = {
            respCode: HttpStatusCode.BadRequest,
            respMessage: '',
            errorMessage: '',
        };

        try {
            response = await this._http
                .post<any>(
                    `${environment.serverApiUrl}auth/v1/tasks/get-user-active-tasks`,
                    request
                    // , { headers: this.API_HEADER}
                )
                .toPromise();

            return UserTaskMapper.GetUserTasks(response);
        } catch (error) {
            response.respCode = HttpStatusCode.InternalServerError;
            response.respMessage = 'Error!';
            response.errorMessage =
                'Something went wrong. User task could not be saved. Try again later or contact someone to help.';
        }

        return response;
    }

    public async updateUserTask(model: IUserTask): Promise<IResponseStatus> {
        let response: IResponseStatus;
        try {
            response = await this._http
                .post<IResponseStatus>(
                    `${environment.serverApiUrl}auth/v1/tasks/update-user-task`,
                    model
                    // , { headers: this.API_HEADER }
                )
                .toPromise();
        } catch (error) {
            //#region stop this!
            // if (error && error instanceof ProgressEvent) {
            //     throw new Error('A connection could not be established. Please contact an administrator.');
            // }
            // throw Error();
            //#endregion

            response.respCode = HttpStatusCode.InternalServerError;
            response.respMessage = 'Error!';
            response.errorMessage =
                'Something went wrong. User task could not be saved. Try again later or contact someone to help.';
        }

        return response;
    }

    public async createUserTask(model: IUserTask): Promise<IResponseStatus> {
        let response: IResponseStatus;
        try {
            response = await this._http
                .post<IResponseStatus>(
                    `${environment.serverApiUrl}auth/v1/tasks/create-user-task`,
                    model
                    // , { headers: this.API_HEADER }
                )
                .toPromise();
        } catch (error) {
            //#region stop this!
            // if (error && error instanceof ProgressEvent) {
            //     throw new Error('A connection could not be established. Please contact an administrator.');
            // }
            // throw Error();
            //#endregion

            response.respCode = HttpStatusCode.InternalServerError;
            response.respMessage = 'Error!';
            response.errorMessage =
                'Something went wrong. User task could not be saved. Try again later or contact someone to help.';
        }

        return response;
    }

    public async updateUserOTPMethod2(model: any): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<IResponseStatus>(
                    `${environment.serverApiUrl}auth/v1/auth/update-user-otpmethod`,
                    model
                    // , { headers: this.API_HEADER }
                )
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async getRatingDetails(): Promise<any> {
        try {
            const response = await this._http
                .post<any>(`${environment.serverApiUrl}auth/v1/auth/get-user-rating-details`, null)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async createRatingDetails(model: any): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<any>(`${environment.serverApiUrl}auth/v1/auth/add-user-rating-details`, model)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }

    public async updateRatingDetails(model: any): Promise<IResponseStatus> {
        try {
            const response = await this._http
                .post<any>(`${environment.serverApiUrl}auth/v1/auth/update-user-rating-details`, model)
                .toPromise();

            return response;
        } catch (error) {
            if (error && error instanceof ProgressEvent) {
                throw new Error('A connection could not be established. Please contact an administrator.');
            }
            throw Error();
        }
    }
}
