import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { IAddressDetails } from 'src/app/core/models/address';
import { AppService } from 'src/app/core/services/app.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
    selector: 'app-quote-schedule',
    templateUrl: './quote-schedule.component.html',
    styleUrls: ['./quote-schedule.component.scss'],
})
export class QuoteScheduleComponent implements OnInit, OnChanges {
    // public quoteDetail = JSON.parse(localStorage.getItem('funeralQuoteDetail'));
    public userDetail: any;
    public quoteEffectiveDate: string;
    public myQuote: any;
    public policyCommencementDate: string;
    public dateFormatter: DatePipe;
    public specialTaCImposedQ: string;
    public residentialAddress = 'Not Available';
    public postalAddress = 'Not Available';
    public userIdentification: any;
    isAccordionOpen: boolean[] = [];

    // public user: any;

    public mainMember: any;
    public children: any;
    public spouses: any;
    public parents: any;
    public others: any;

    @Input()
    public insurerSelectedOnChild: any; //"metropolitan";//by deffault;
    private userResidentialAddress: IAddressDetails;
    private userPostalAddress: IAddressDetails;

    mobileView = false;
    mobileViewSub: Subscription;

    @Input()
    public selectedQuoteOption: any;

    @Input()
    public paymentDetailSelected: any;

    @Input()
    public beneficiarylSelected: any;

    constructor(
        public datepipe: DatePipe,
        private readonly _userService: UserService,
        private store: Store<AppState>,
        private readonly _appService: AppService
    ) {
        this.dateFormatter = datepipe;

        this.mobileViewSub = this._appService.mobileView$.subscribe(result => {
            this.mobileView = result;
        });
    }

    toggleAccordion(index: number): void {
        // Toggle the state for the selected beneficiary
        this.isAccordionOpen[index] = !this.isAccordionOpen[index];
    }

    ngOnChanges(changes: SimpleChanges) {
        this.store
            .select((state: any) => state?.user)
            .subscribe(data => {
                this.userDetail = data?.userDetails;

                this.userIdentification = null;

                //find a better way to build this later.
                if (this.userDetail?.profileDetails?.nationalIdNumber) {
                    this.userIdentification = this.userDetail?.profileDetails?.nationalIdNumber;
                }

                if (this.userDetail?.profileDetails?.passportNumber) {
                    if (this.userIdentification !== null) {
                        this.userIdentification = '/ ' + this.userDetail?.profileDetails?.passportNumber;
                    } else {
                        this.userIdentification = this.userDetail?.profileDetails?.passportNumber;
                    }
                }
            });
    }

    ngOnInit(): void {
        this.isAccordionOpen = new Array(this.beneficiarylSelected?.length)?.fill(false);
        this.loadUserAddresses();
        // this._userService.GetUserProfile().then(response => {

        // });

        this.specialTaCImposedQ = 'NO'; //policy.specialTaCImposed;

        var today = new Date();
        var aMonthToday = new Date();

        this.quoteEffectiveDate = this.dateFormatter.transform(today, 'dd MMM yyyy');
        aMonthToday.setMonth(today.getMonth() + 1);
        aMonthToday.setUTCDate(1);

        this.policyCommencementDate = this.dateFormatter.transform(aMonthToday, 'dd MMM yyyy');
    }

    public main(): void {
        this.store
            .select((state: any) => state?.funeralRelatedParties)
            .subscribe(data => {
                const benefits = data.relatedParties;

                if (benefits?.length !== 0) {
                    this.mainMember = benefits?.find((x: { partyType: string }) => x.partyType == 'MainMember');
                    this.children = benefits.filter((x: { partyType: string }) => x.partyType == 'Child');
                    this.spouses = benefits.filter((x: { partyType: string }) => x.partyType == 'Spouse');
                    this.parents = benefits.filter((x: { partyType: string }) => x.partyType == 'Parent');
                    this.others = benefits?.filter((x: { partyType: string }) => x.partyType == 'ExtendedFamily');
                }
            });
    }

    public loadUserAddresses() {
        this._userService.getUserAddress().then((response: { contacts: IAddressDetails[] }) => {
            if (response.contacts === null || response.contacts === undefined) response.contacts = [];

            this.userResidentialAddress = response.contacts.find((x: { addressType: string }) => x.addressType === 'Residential');
            this.userPostalAddress = response.contacts.find((x: { addressType: string }) => x.addressType === 'Postal');

            if (this.userResidentialAddress !== undefined && this.userResidentialAddress !== null) {
                this.residentialAddress =
                    this.userResidentialAddress.street +
                    ' ' +
                    this.userResidentialAddress.subUrb +
                    ' ' +
                    this.userResidentialAddress.city +
                    ' ' +
                    this.userResidentialAddress.postalCode;
            }

            if (this.userPostalAddress !== undefined && this.userPostalAddress !== null) {
                this.postalAddress =
                    this.userPostalAddress.street +
                    ' ' +
                    this.userPostalAddress.subUrb +
                    ' ' +
                    this.userPostalAddress.city +
                    ' ' +
                    this.userPostalAddress.postalCode;
            }
        });
    }
}
